import React, { Component } from "react";
import Loader from "../Loader";
import { io } from 'socket.io-client'

import axios from "axios";
import Cookie from "js-cookie";
import sendpush from "../../components/admin/sendNotification";
import commonData from "../../importanValue";
import audioFile from "../../../src/sent.mp3";
import recieve from "../../../src/recieve.mp3";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import "./chatStyles.css";
const socket = io(commonData["api"]);
// const newMsg = socket.on("newmessage", (data) => console.log("new msgs", data))
class AdminChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      Chatdata: [],
      message: "",
      to: "",
      from: "",
      userChatClicked: false,
      usersList: [],
      idDelete: 0,
    };
    this.lastId = 0;
  }

  componentDidMount() {
    this.getEachUserInfo();
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    // this.timer();
    // socket.on("connect", () => console.log("socket", socket))
    // socket.emit("admin", "admin")

    // const uid = localStorage.getItem("num");
    socket.emit("user", "Admin")
    socket.on("msg", (socket2) => console.log("socket", socket2))
    // const newMsg = socket.on("newmessage", (data) => {
    //   console.log("new msgs", data)

    //   this.setState(p => ({ Chatdata: [...p.Chatdata, data[0]] }))
    // })
    const { num } = this.props;
    if (num !== "") {
      this.setState(
        { to: num, userChatClicked: true, isLoading: true },
        this.getData
      );
    }
  }
  // componentDidUpdate() {

  // }

  scrollToBottom = () => {
    const element = document.getElementById("messageend");
    element.scrollIntoView();
    // this.timer();
  };

  getEachUserInfo = async () => {
    const token = Cookie.get("jwt_token");
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
      "Access-Control-Allow-Origin": "*",
    };
    const body = {
      type: "getUserChatList",
      search: "",
      qid: "Admin",
    };
    try {
      this.setState({ isLoading: true });

      const data = await axios.post(`${commonData["api"]}/support`, body, {
        headers,
      });
      this.setState({
        isLoading: false,
        usersList: data.data[0],
      });
    } catch (err) {
      NotificationManager.error(`Something Went Wrong`);
      this.setState({ isLoading: false });
    }
  };

  getData = async () => {
    const { to } = this.state;
    const token = Cookie.get("jwt_token");
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
      "Access-Control-Allow-Origin": "*",
    };
    const body = {
      type: "getUserChat",
      search: to,
      qid: "Admin",
    };

    try {
      this.setState({ isLoading: true });

      const data = await axios.post(`${commonData["api"]}/support`, body, {
        headers,
      });
      console.log(
        data.data[0].filter((e) => e.type === "recieve").slice(-1)[0].id
      );
      this.lastId = data.data[0]
        .filter((e) => e.type === "recieve")
        .slice(-1)[0].id;
      this.setState(
        {
          isLoading: false,
          Chatdata: data.data[0],
          lastId: data.data[0].filter((e) => e.type === "recieve").slice(-1)[0]
            .id,
        },
        this.scrollToBottom
      );

    } catch (err) {
      NotificationManager.error(`Something Went Wrong`);
      this.setState({ isLoading: false });
    } const newMsg = socket.on("newmessage", (data) => {
      console.log("new msgs", data)
      this.setState(p => ({ Chatdata: [...p.Chatdata, data[0]] }), this.scrollToBottom)
    })
  };
  renderLeftData = (ee) => {
    const { from, username } = ee;
    return (
      <div
        className="eachData"
        onClick={() =>
          this.setState(
            {
              userChatClicked: true,
              isLoading: true,
              to: from,
            },
            this.getData
          )
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div>
              <h4>
                {from} ( {username} )
              </h4>
            </div>
            <div style={{ fontWeight: 200, opacity: 0.6 }}>
              <p>{"Check New Messages..."}</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            ➤{" "}
          </div>
        </div>
        <hr style={{ marginTop: 5, opacity: 0.6 }} />
      </div>
    );
  };

  deleteMsg = async () => {
    const { idDelete, Chatdata } = this.state;
    const token = Cookie.get("jwt_token");
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
      "Access-Control-Allow-Origin": "*",
    };
    const body = {
      type: "AdminMsgDelete",
      search: "",
      qid: idDelete,
    };
    if (window.confirm("Do you really want to remove this Message ?")) {
      try {
        const data = await axios.post(
          `${commonData["api"]}/admin/qbankdata`,
          body,
          { headers }
        );
        // console.log(CouponData);
        this.setState(
          {
            Chatdata: Chatdata.filter((e) => e.id != idDelete),
          },
          NotificationManager.success(`Message Deleted Succesfully...`)
        );
      } catch (err) {
        NotificationManager.error(`Something Went Wrong`);
      }
    }
  };


  sendMsg = async (e) => {
    e.preventDefault();
    const { message, to } = this.state;

    const token = Cookie.get("jwt_token");
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
      "Access-Control-Allow-Origin": "*",
    };
    // const uid = localStorage.getItem("num");
    // from = user === "1" ? "Admin" : uid;
    // to = user === "1" ? to : "Admin";
    const body = {
      type: "sendUserMsg",
      search: message,
      qid: `${"Admin"}_${to}_${new Date().toLocaleTimeString()}`,
    };
    socket.emit("sentmessage", body)


    try {
      const data = await axios.post(`${commonData["api"]}/support`, body, {
        headers,
      });

      this.setState(
        (p) => ({
          Chatdata: [...p.Chatdata, data.data[0][0]],
          message: "",
        }),
        () => {
          this.scrollToBottom();
          const audio = new Audio(audioFile);
          audio.play();
        }
      );
      await sendpush({
        title: `New Message From Admin...`,
        message: `Message : ${message}\n\nFrom : ${"NavaCHAITANYA Competitions"}\n`,
        filters: [
          {
            field: "tag",
            key: "user",
            relation: "=",
            value: to,
          },
        ],
        url: `/support/user/${to}`,
      });
    } catch (err) {
      NotificationManager.error(`Something Went Wrong`);
      this.setState({ isLoading: false });
    }
  };

  timer = () => {
    this.countdown = setInterval(
      // () => this.lastId > 0 && this.checkForNewMessage,
      this.checkForNewMessage,
      3000
    );
  };
  componentWillUnmount() {
    clearInterval(this.countdown);
    // this.setState({ to: "", userChatClicked: false });
  }
  checkForNewMessage = async (e) => {
    // clearInterval(this.countdown);

    const { lastId, to, Chatdata } = this.state;
    const token = Cookie.get("jwt_token");
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
      "Access-Control-Allow-Origin": "*",
    };
    const body = {
      type: "checkAdminForNewMessage",
      search: this.lastId,
      qid: to,
    };
    try {
      const data = await axios.post(`${commonData["api"]}/support`, body, {
        headers,
      });
      console.log(data.data);
      if (
        (data.data.length > 1 && data.data[0][0].result === "true") ||
        (data.data.length === 1 && data.data[0].result === "true")
      ) {
        const old = Chatdata;
        let newData = data.data[0].filter((e) => !old.includes(e));
        // console.log(data.data[0].filter((e) => e.type === ""sent""));
        this.lastId =
          (data.data.length > 1 &&
            data.data[0].filter((e) => e.type === "sent").slice(-1)[0].id) ||
          (data.data.length === 1 &&
            data.data.filter((e) => e.type === "sent").slice(-1).id);
        // console.log(
        //   data.data.length > 1 &&
        //     data.data[0].filter((e) => e.type === "recieve").slice(-1)[0].id
        // );
        this.setState(
          (p) => ({
            Chatdata: old.concat(newData),
            // message: "",
            // lastId: data.data[0].filter((e) => e.type === "sent").slice(-1)[0]
            //   .id,
          }),
          () => {
            // clearInterval(this.countdown);
            this.scrollToBottom();
            const audio = new Audio(recieve);
            audio.play();
            // this.timer();
          }
        );
      }
    } catch (err) {
      console.log(err);
      // NotificationManager.error(`Something Went Wrong`);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, Chatdata, message, usersList, userChatClicked } =
      this.state;
    const uid = localStorage.getItem("num");
    return (
      <>
        {!isLoading && userChatClicked && (
          <div className="chatmainDiv">
            <div className="chatbackground">
              <div>
                {Chatdata.map((eachMsg) => (
                  <div
                    className={`chat ${eachMsg.to !== "Admin" ? "sentchat" : "recievechat"
                      }`}
                  >
                    <span
                      className={`fromchat `}
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: 10,
                      }}
                    >
                      {eachMsg.from} ({" " + eachMsg.senttime} ){" "}
                      <i
                        className="fas fa-trash"
                        style={{ cursor: "pointer", marginTop: 5 }}
                        onClick={() =>
                          this.setState(
                            {
                              idDelete: eachMsg.id,
                            },
                            () => this.deleteMsg()
                          )
                        }
                      ></i>
                    </span>

                    <p
                      className={`msg ${eachMsg.from === "Admin" ? "sentChhat" : "recieveChhat"
                        }`}
                      dangerouslySetInnerHTML={{ __html: eachMsg.message }}
                    ></p>
                  </div>
                ))}
                <div
                  id="messageend"
                  style={{ float: "left", clear: "both" }}
                ></div>
              </div>
            </div>{" "}
            <div className="chatMsgDiv">
              <form onSubmit={this.sendMsg}>
                <input
                  type={"text"}
                  className="chatmsginput"
                  required
                  placeholder="Message"
                  value={message}
                  onChange={(e) => this.setState({ message: e.target.value })}
                />
                <input type={"submit"} className="chatsendbtn" value={"➤"} />
              </form>
            </div>
          </div>
        )}
        {!isLoading && !userChatClicked && usersList.length > 0 && (
          <div className="sidebar-chat">
            {usersList.map((e) => {
              return this.renderLeftData(e);
            })}
          </div>
        )}
        {isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <Loader />
          </div>
        )}

        <div>
          <NotificationContainer />
        </div>
      </>
    );
  }
}

export default AdminChat;
